import React, { ReactElement } from 'react'
import { graphql, PageProps } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'

import { SiteMetaData } from '../utils/types'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import TriangleSeparator from '../components/TriangleSeparator'

type Data = {
  file: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  site: SiteMetaData
}

const AboutPage = ({ data }: PageProps<Data>): ReactElement => {
  return (
    <Layout>
      <SEO title="About" />
      <TriangleSeparator />

      <section className="container mx-auto">
        <article className="post__body">
          <div className="grid grid-cols-12 gap-x-4 md:grid-flow-row md:gap-x-8 md:grid-rows-3 items-start mb-8">
            <div className="col-span-8 col-start-3 md:col-span-4 rounded-full w-full p-8 mb-8 relative shadow-outer bg-color-switch">
              <Img
                fluid={data.file.childImageSharp.fluid}
                alt="Ellie in memoji form."
              />
            </div>

            <div className="md:mt-8 col-span-12 md:col-span-8 md:row-span-3 md:border-l md:pl-8">
              <h1 className="header2">Who Am I?</h1>
              <p>
                My name is Ellie{' '}
                <a className="tooltip">
                  Strejlau
                  <span className="tooltip-text">
                    (This is pronounced Stray-low with the last syllable
                    sounding like &ldquo;ow&rdquo; or &ldquo;ouch&rdquo;.)
                  </span>
                </a>
                . I&rsquo;m a mom, wife and software developer from New York. I
                specialize in web application architecture and hold both a
                Bachelor and Master of Science in Computer Programming and
                Information Systems.
              </p>

              <h2>What Makes Me... Me</h2>
              <ul>
                <li key="about-me-0">
                  My fondness for programming started out as a hobby in 1999 and
                  I haven&rsquo;t grown tired of it (yet).
                </li>
                <li key="about-me-1">
                  I am a nearly stereotypical introvert; I tend to be
                  introspective, value deep conversation, and need alone time to
                  recharge.
                </li>
                <li key="about-me-2">
                  I also really appreciate good food, drink, and games with
                  loved ones.
                </li>
                <li key="about-me-3">
                  I am a rule follower and hold myself to high standards.
                </li>
                <li key="about-me-4">
                  I&rsquo;m not super opinionated on many things.
                </li>
                <li key="about-me-5">
                  When I <em>do</em> have an opinion about something, I
                  appreciate being able to give that input.
                </li>
                <li key="about-me-6">
                  I strongly value my personal space. If I let you into it, that
                  means I trust you.
                </li>
                <li key="about-me-7">
                  I act weird and use profanity around people who know me best.
                </li>
              </ul>

              <h3>Values</h3>
              <ul>
                <li key="about-values-0">
                  This should go without saying, but always treat others with
                  respect.
                </li>
                <li key="about-values-1">
                  There is no such thing as over-communication.
                </li>
                <li key="about-values-2">
                  When working with a team, use the team chat room as much as
                  possible. Avoid private 1-1 chats.
                </li>
                <li key="about-values-3">
                  The best kind of feedback is timely and detailed.
                </li>
                <li key="about-values-4">
                  Give people space to create. Treat colleagues like the adults
                  that they are.
                </li>
                <li key="about-values-5">
                  A healthy work-life balance is always a requirement and varies
                  depending on the person.
                </li>
              </ul>
            </div>

            <section className="col-span-12 md:col-span-3 md:row-span-2 sm:flex md:block self-start">
              <div className="sm:w-1/2 md:w-full">
                <h4>Personality</h4>
                <p>
                  <strong>Strengths:</strong> Empathy, humility, and brevity
                </p>
                <p>
                  <strong>Weaknesses:</strong> Difficult conversations,
                  self-advocacy, and confrontation
                </p>
              </div>
              <div className="sm:w-1/2 md:w-full">
                <h4>Favorites</h4>
                <p>
                  <strong>Hobbies:</strong> Cooking, baking, playing video
                  games, and reading
                </p>
                <p>
                  <strong>Drink:</strong> Coffee
                </p>
                <p>
                  <strong>Seasons:</strong> Autumn or winter
                </p>
                <p>
                  <strong>Story Genres:</strong> Historical Non-Fiction, True
                  Crime, Science Fiction, Mystery
                </p>
              </div>
            </section>
          </div>
        </article>
      </section>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "memoji-square.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
